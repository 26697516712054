import React from 'react'
import { Link } from 'react-router';
import './about.css'
const Alert = () => {
  return (
    <>
    
    <br></br>


<div className='blog'>
    
   
    <div className='last_header'>
       
        <div className='nav'>
        
            <ul>
                <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                <li><Link to ='/mat' className='link'>MAT</Link></li>
                <li><Link to ='/pms' className='link'>PMS</Link></li>
                
               
                <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                <li><Link to ='/induction' className='link'>Induction</Link></li>
                <li><Link to ='/das' className='link'>DAS</Link></li>
                <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                <li><Link to ='/adas' className='link'>ADAS</Link></li>

                
                
            </ul>

        </div>
        
        
    </div>
    
</div>


        
<div class="row">
    
  
    <div class="about-col">
      

        <h1>Alert Seat </h1>
       
        <p>A proven biological rhythm technology utilizing PEMF (Pulsed Electro Magnetic Field) is featured as anti-sleep device. The PEMF device emits electromagnetic waves at different frequencies in order to stimulate and encourage your body natural recovery process.</p>
        <p>Total of 2 coils are installed inside the back Pack. The coils are strategically placed near shoulder area and lower spinal cord for enhanced biological effect over human body. This improves driver’s blood circulation, boosts energy, and relieves back pain while driving.  </p>



                <h3>Advantages of this technology</h3>

                <div className='list'>
                <ul>
                    <li>Reduces pain, fatigue, and effect of stress on the body</li>
                    <li>Improves energy, circulation of blood and tissue oxygenation, cellular detoxification.</li>
                    <li>Anti-sleep and focused mentality</li>
                </ul>
                <p>Overall, it can be seen as a combination of PEMF technology and Static Analysis system solution which renders continuous body functional enhancement of driver, detection of driver’s head position, steering wheel movement.</p>

                </div>

                

               
                <h3>Benefits</h3>
                <div className='benifits'>
                    
                    <p>The intense concentration with alert, active thought processing state with little or no stress and reduces physical and mental fatigue without causing drowsiness, keeping mind and reflexes sharp yet stress free also used to reduce neck and shoulder fatigue.</p>
                </div>
                
                <h3>General instruction</h3>
                <div className='inst'>
                    
                    <ul>
                        <li>Brace/Place the Seat with PEMF coil posture towards body.</li>
                        <li>Connect the USB to the Wall adapter, Laptop, Desktop, Battery pack.</li>
                        <li>Red led on the starts blinking once the device is powered on.</li>
                        <li>•	Red led blinking indicates therapy has started successfully.</li> </ul>
                </div>
            
                
                
                
                <h3>Important safeguards</h3>
                <div className='inst'>
                    
                    <p>To ensure the safety of the user, the patient, and the bystanders, please pay attention to the following safety instructions.</p>
                    <ul>
                       <li>Pacemakers: people with Heart pacemakers, Deep brain stimulators and other electronic implants should not use the device at all.</li>
                    </ul>
                </div>

                <h3>Cautions</h3>
                <div className='inst'>
                    
                    <p>The intense concentration with alert, active thought processing state with little or no stress and reduces physical and mental fatigue without causing drowsiness, keeping mind and reflexes sharp yet stress free also used to reduce neck and shoulder fatigue. </p>
                    <ul>
                       <li>Do not modify the parts or repair the unit by yourself.</li>
                       <li>Opening device voids warranty and return guaranty.</li>
                       <li>Do not clean by water directly, water get inside may cause malfunction.</li>



                    </ul>
                </div>
                
        
    </div>
    <div class="about-col">
    
    <img src='image/alert.png'></img>

                
    </div>
   


   
   
 </div>
 
    


    </>

    
  )
}

export default Alert