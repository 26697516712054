import React from 'react'
import './medical.css'
import { Link } from 'react-router-dom'
const Industrial = () => {
  return (
    <>
      <div className='home'>
               
               <div className='trending2'>
                           <div className='container'>
                               <div className='left_box'>
                                   <div className='header'>
                                       <div className='heading'>
                                           <h2 >Products</h2>
                                       </div>
                                       
                                   </div>
                                   <div className='products'>
                                       <div className='container'>
                                          
                                           <div className='box'>
                                               <div className='img_box'>
                                                   <img src='image/silver.png' alt=''></img>
                                                   <h2>Colloidal silver</h2>
                                                   <Link to ='/silver'><button className='btn'>View</button></Link>                                        
                                               </div>                
                                           </div>
                                          
                                       </div>
                                   </div>
                               </div>
                               <div className='right_box'>
                                   <div className='container'>
                                       <div className='testimonial'>
                                           <div className='head'>
                                               <h3>Services</h3>
                                           </div>
                                           <div className='detail'>
                                               <div className='img_box'>
                                                   <Link to ='/medical'><button className='btn'>Medical</button></Link>
                                               </div>
                                               
                                           </div>
                                           <div className='detail'>
                                               <div className='img_box'>
                                                   <Link to ='/automotive'><button className='btn'>Automotive</button></Link>
                                               </div>
                                               
                                           </div>
                                           <div className='detail'>
                                               <div className='img_box'>
                                                   <Link to ='/industrial'><button className='btn'>Industrial</button></Link>
                                               </div>
                                               
                                           </div>
                                           <div className='detail'>
                                               <div className='img_box'>
                                                   <Link to ='/iot'><button className='btn'>Iot</button></Link>
                                               </div>
                                               
                                           </div>
                                           <div className='detail'>
                                               <div className='img_box'>
                                                   <Link to ='/kitchen'><button className='btn'>Kitchen appliance</button></Link>
                                               </div>
                                               
                                           </div>
                                          
                                          
                                                     
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
               
               
                   </div>
    </>
  )
}

export default Industrial