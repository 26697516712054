import React from 'react'
import { Link } from 'react-router';
import './about.css'

const Biofield = () => {
  return (
    <>



<br></br>
       
       
       <div className='blog'>
           
          
           <div className='last_header'>
              
               <div className='nav'>
               
                   <ul>
                       <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                       <li><Link to ='/mat' className='link'>MAT</Link></li>
                       <li><Link to ='/pms' className='link'>PMS</Link></li>
                       
                      
                       <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                       <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                       <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                       <li><Link to ='/induction' className='link'>Induction</Link></li>
                       <li><Link to ='/das' className='link'>DAS</Link></li>
                       <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                       <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                       <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                       <li><Link to ='/adas' className='link'>ADAS</Link></li>
   
                       
                       
                   </ul>
   
               </div>
               
               
           </div>
           
       </div>
   
    
    <div class="row">


<div class="about-col">
  

    <h1>Biofield</h1>
    
    <h3>24/7 Non-Stop Operation.</h3>
    <p>Designed and Tuned to imitate the 24/7 Resonant frequency(Magnetic Field of the Mother Earth) allowing you to transfeorm any space into an infrasonic Cocoon for Cellular Regeneration and to act as the Environmental Protection Technology against all kinds of Electromagnetic Radiations emitted from electronics.
           </p>
           <p>Ideal for use at home with   Children’s and pets or Office/Lounge with colleagues and Associates to be tuned to the precise frequency of life. Ideal for use in kindergartens, lounge,spas,gyms or any indoor or outdoor space </p>

            <div className='inst'>
                  <p>Biofield PEMF 6 or 12 Electromagnet System is powered by 100V-240V compatible 12 volt DC power supply with reduceed EMF and RF</p>               
                            </div>
           
            <h3>Six Electromagnetic Coil</h3>
            <img src='image/bio-6.jpeg'></img>
            
    <h3>Twelve Electromagnetic Coil</h3>
    <img src='image/BF-2.png'></img>

           
           

           
   
            
            
            
           
            
            
           
    
</div>
<div class="about-col">
<img src='image/BF-1.png'></img>
  
                    
        </div>
       


</div>
 
    

    </>
)
}

export default Biofield