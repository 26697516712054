import React from 'react'
import './footer.css'

import { FaAddressCard } from "react-icons/fa";
import { FaShippingFast } from "react-icons/fa";
import { ImHeadphones } from "react-icons/im";
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <div className='footer'>
        <div className='container'>
            <div className='left-box'>
                <div className='box'>
                    <div className='icon_box'>
                    <FaAddressCard />
                    </div>
                    <div className='detail'>
                        <h3>Address</h3>
                        <p>Lipika Technologies</p>
                        <p>56, 19th Main Road, 2nd Block Rajajinagar
                        Bangalore, Karanataka -560010</p>
                    </div>
                    </div>
                    <div className='box'>
                    <div className='icon_box'>
                    <FaShippingFast />
                    </div>
                    <div className='detail'>
                        <h3>Dilevery</h3>
                        <p>World Wide Dilevery</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='icon_box'>
                    <ImHeadphones />
                    </div>
                    <div className='detail'>
                        <h3>24/7 support</h3>
                        <p>Contact Us</p>
                    </div>
                </div>
                
                </div>
                <div className='right_box'>
                    <div className='header'>
                        <img src='image/lipika.png'></img>
                        
                        <h2>Lipika Technologies</h2>
                        <p>Committed to Quality and Client satisfaction</p>
                    </div>
                    <div className='bottom'>
                        <div className='box1'>
                            <h3>Links</h3>
                            <ul>
                            
                                <li><Link to='/about' className='link'>About</Link></li>
                                <li><Link to='/shop' className='link'>Product</Link></li>
                                <li><Link to='/contact' className='link'>Contact</Link></li>
                              
                            </ul>
                        </div>
                        <div className='box'>
                            <h3>Contact Us</h3>
                            <ul>
                                <li>Mon-Fri: 9Am -6Pm</li>
                                <li>9880791560</li>
                                <li>9901657755</li>
                                <li>9108527218</li>
                                <li>info@lipikatech.in</li>
                               
                               
                            </ul>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
  
    </>
  )
}

export default Footer
