import React from 'react'
import { Link } from 'react-router';
import './about.css'

const Aumpulser = () => {
  return (
    <>
    <br></br>


    <div className='blog'>
        
       
        <div className='last_header'>
           
            <div className='nav'>
            
                <ul>
                    <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                    <li><Link to ='/mat' className='link'>MAT</Link></li>
                    <li><Link to ='/pms' className='link'>PMS</Link></li>
                    
                   
                    <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                    <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                    <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                    <li><Link to ='/induction' className='link'>Induction</Link></li>
                    <li><Link to ='/das' className='link'>DAS</Link></li>
                    <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                    <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                    <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                    <li><Link to ='/adas' className='link'>ADAS</Link></li>

                    
                    
                </ul>

            </div>
            
            
        </div>
        
    </div>

























        
    <div class="row">
    
  
        <div class="about-col">
          

            <h1>AUM PULSER</h1>
           
            <h3> A non-invasive device for sound sleep and pain management.</h3>
                    <p>Sleeping is a basic human need, like eating, drinking water, and breathing. Like these other needs, sleeping is vital for good health and well-being throughout your lifetime. 
</p><p>Many people lack sleep due to the stress of daily life and due to specific health conditions such as insomnia, pain or many other diseases. Sleep is important because it affects many of your body’s systems. Not getting enough sleep or enough quality sleep raises your risk for heart and respiratory problems and affects your metabolism and ability to think clearly and focus on tasks.
 </p><p>PEMF therapy has been proven a great way to fix the sleep and pain related problems without drugs. Where Pulsed PEMF therapy sends magnetic energy into the body. These energy waves work with your body's natural magnetic field to improve sleep and pain related issues.
</p><p>AUM PULSER works on PEMF therapy, which re-tunes your brain for maximum efficiency with low frequency and works for better sleep, and also stimulates the cellular process to lead to faster tissue and cellular regeneration for pain relief.
</p><p>AUM PULSER therapy has shown benefits in improving sleep quality. By promoting relaxation, reducing stress, and balancing the body’s natural rhythms, and also helps individual’s better sleep patterns and wake up feeling more refreshed.
</p>
                    <p>Aum Pulser is a PEMF device that generates a pulsating magnetic field to sustain individuals suffering from sleeping disorders, anxiety, or depression, as well as those suffering from pain or recovering from injury, or seeking anti-aging effects. </p>
                    
                    <p>AUM PULSER works on the nature of pulsed electromagnetic fields (PEMF) which generates a pulsed unidirectional waveform of brain wave frequency in addition to intensity of 0.6 Hz to 9.6Hz. These waveforms are used for sleep; </p>
                    <p>Many people lack sleep due to the stress of daily life and specific health conditions such as insomnia, pain or many other diseases, AUM PULSER for sleep is a great way to fix the issue without drugs.</p>
                    <p>Aum Pulser offers a non-drug approach to sleep, pain management and a potential decrease of medication use and dependency. </p>
                    <h3>This has the following program modes for different end uses.</h3>

                    <div className='list'>
                    <ul>
                        <li>H-sleep: For dealing with sleep related problems.</li>
                        <li>D-sleep: For extremely sleep deprived or highly stressed.</li>
                        <li>Awake: For working late/driving without fatigue.</li>
                        <li>Fast Sleep: To fall asleep faster.</li>
                        <li>Wellness: Reduce stress level and helps to feel calmer. </li>
                        <li>Revitalize: For pain and injury management.</li>
                        <li>Schumann: To connect with earth’s natural frequency for rejuvenation and balance.</li>
                        <li>Manual: Set for any pulsed magnetic field frequency.</li>
                    </ul>

                    </div>
                    <div className='img'>
                        <p>The Electromagnetic coil is placed under the mattress/ pillow(always) based on a practical understanding of the point of pain and the control unit is used to set up the program mode and timer to initialize.
                        </p>
                        <p>One User: The coil is placed under the neck and thigh region as shown below.</p>
                        <p>Two User: Each coil is placed under each individual.</p>
                       
                    

                    </div>

                   
                    <h3>Benefits</h3>
                    <div className='benifits'>
                        
                        <ul>
                            <li>PEMF is catching on as a non-invasive way to approach injuries, chronic pain, and even chronic conditions like depression and diabetes.</li>
                            <li>This system is designed for better sleep, radically accelerated recovery, enhanced daytime performance, and incredible anti-aging effects.</li>
                            <li>Regular use of PEMF therapy, besides promoting various healing mechanisms, has been found to have substantially beneficial neuroendocrine, neurological, and psychological effects. as well as having the ability to promote bone, tissue, and nerve regeneration.</li>
                        </ul>
                    </div>
                    
                    <h3>General instruction to set up aum pulser</h3>
                    <div className='inst'>
                        
                        <ul>
                            <li>1).Plug the power adapter cable into the large jack at the back of the program controller.</li>
                            <li>2).Plug the power adaptor into the electricity outlet on the wall(100-240 volts AC). Use an International pin adaptor if required.</li>
                            <li>3).Plug the 2 coil sets into the remaining 2 jacks at the back of the program controller.</li>
                            <li>4).Press the "On/Off" button on the program controller, the display shows "AUM PULSER" and instantly boots to "H-SLEEP" mode(default).NOTE: Your system is now ready to use and you need to select and set up the mode, Timer, and Amplitude.</li>
                            <li>5).Press "Enter" then the Mode changes from light to dark shaded and uses the "Up"/"Down" Arrow key to arrive at desired mode.</li>
                            <li>6).The once-desired mode is selected. Press the "Enter" key. The display now shows the mode with the timer set to 8:00 hours and Amplitude set to 10%.NOTE: You now need to set time duration and Amplitude.</li>
                            <li>7).Press "Enter" then the Timer selection changes from light to dark shade and use the "Up" or "Down" Arrow to set the time duration for which you wish to use the device. Time can be set from 15 minutes to 12 hours with changes in multiples of 15 minutes.</li>
                            <li>8).Press "Enter" then the Amplitude selection changes from light to dark shade and use the "Up" or "Down" key to select the Amplitude. Amplitude can be set from 10% to 100% with changes of 10%.</li>
                            <li>9).Press "Enter" and the device begins to function. This is established by the blinking of the * in the display and the blue LED on the program controller.NOTE: If you make a mistake anywhere in the steps define above, press the "Enter" button, and then restart from step 5.</li>
                        </ul>
                    </div>
                    <div className='types'>
                        <h3>Aum Pulser Dual Coil</h3>
                        <img src='image/aumpulser.png'></img>
                        <h3>Aum Pulser single Coil</h3>
                        <img src='image/aps.png'></img>

                    </div>
                    
                    
                    
                    <h3>PRECAUTIONS</h3>
                    <div className='inst'>
                        
                        <p>To ensure the safety of the user, and the by stander, please pay attention to the following safety instructions.</p>
                        <ul>
                            <li><b>PREGNANCY:</b> Pregnant women Should Not Use the device at all.</li>
                            <li><b>PACEMAKERS:</b> people with Heart Pacemakers, Deep Brain Stimulators, and other Electronic implants Should Not use the device at all.</li>
                            <li>Place only the electromagnetic under the bedding. <b>DO NOT put the control box unit</b> under pillows or mattresses as overheating may occur.</li>
                            <li><b>NORTH POLE:</b>  North side of the electromagnetic is positioned towards the body at all times.</li>
                            <li><b>SOUTH POLE:</b> The south pole is located where the cord ties into the bottom side of the electromagnet.</li>
                            <li><b>CLEANING:</b> AUM PULSER device can be gently cleaned with a piece of cloth.</li>
                            <li>Use adequate padding, i.e. a folded towel or small pillow between body parts and coil. The coil should NOT directly touch the body parts.
                            </li>
                        </ul>
                    </div>
                    <h3>DISCLAIMER</h3>
                    <div className='inst'>
                       
                        <ul>
                            <li>Lipika Technologies makes no medical claims, real or implied, as to the benefit of our device and methods. </li>
                            <li>AUM PULSER is strictly a performance Enhancement Tool utilizing Natural Field supplementation to achieve deeper states of sleep, improved performance, and recovery from pain.</li>
                            <li>Our product is not intended to be used to diagnose, treat, cure, or prevent any disease. </li>
                            <li>Any information provided by us is not to be construed as medical advice. The information herein has not been evaluated by FDA.</li>
                            <li>Worldwide, there are no governmental health agencies that recognize a need to supplement natural coil fields.</li>
                        </ul>
                    </div>
            
        </div>
        <div class="about-col">
        
        <img src='image/aumpulser.png'></img>
        <img src='image/aumpulser1.png'></img> 
        <img src='image/ap.png'></img>
        <img  src='image/side-sleep.png' className='side'></img>
        
        <img  src='image/top-sleep.png' className='top'></img>
                    
        </div>
       


       
       
     </div>
     
        


          
    </>
    
    
  )
}

export default Aumpulser