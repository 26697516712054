import React from 'react'
import { Link } from 'react-router';
import './about.css'
const Induction = () => {
  return (
    <>

    <br></br>
    
    
        <div className='blog'>
            
           
            <div className='last_header'>
               
                <div className='nav'>
                
                    <ul>
                        <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                        <li><Link to ='/mat' className='link'>MAT</Link></li>
                        <li><Link to ='/pms' className='link'>PMS</Link></li>
                        
                       
                        <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                        <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                        <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                        <li><Link to ='/induction' className='link'>Induction</Link></li>
                        <li><Link to ='/das' className='link'>DAS</Link></li>
                        <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                        <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                        <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                        <li><Link to ='/adas' className='link'>ADAS</Link></li>
    
                        
                        
                    </ul>
    
                </div>
                
                
            </div>
            
        </div>
    



        
        <div class="row">
    
  
    <div class="about-col">
      

        <h1>Innovative Induction Cooktops </h1>
       
        <h3>We Design the product based on customer requirements.</h3>
               <p>The method of Induction heating useselectromagneticenergy to heat an electrically conducting material. The electromagnetic field induces Eddy current in the conducting material thereby causing heat through joule heating. </p>
                <p>The pot used in induction heater is ferromagnetic so that,it heatsup by accepting the electromagnetic energy from the induction heater. The heat generating in the pot is then transferred to the water used for cooking. Thisiswhythe pot remainscool whileitscontent ishot. But the cooking pot must be ferromagnetic types,one like Iron or Steel .Non ferromagnetic pots made up of aluminium or copper cannot be
used in induction heating.</p>
<p>The induction cooker hasalarge circular coil made up of enameled copper windingsplaced under the space for the pot. When ACof aparticular frequency passes through the coil,an oscillating magnetic field develops which induces electric current into the pot material. When this Eddy current (passesthe moleculesof the pot material) and heat develops. Thisheat is transferred to the water to boil the contents in the pot</p>
                <p>Efficient induction cooking takesplace if steel vesselsare used because of its magnetic propertiesand the steel concentratesall the induced current in the surface so that the heating effect becomes stronger. Aluminum and other non magnetic materialsare not good for induction cooking, since the induced magnetic field penetratesdeep into the material creating only little resistance. In Coil heater, much of the current is wasted and efficiency reduces to 20-40% .But in Induction heating,energy wastage is very low and efficiency is 80-90% .</p>
                
                
                <h3>Advantages of Induction cooking.</h3>

                <div className='list'>
                <ul>
                   <li>Energy level and power consumption can be controlled.</li>
                   <li>No wastage of energy through heat loss .</li>
                   <li>No shock and burning .</li>
                   <li>Only the content of the pot heats. Heat will not pass into the surrounding air.</li>
                   <li>Power usage can be controlled for different types of cooking. This prevents wastage of energy.</li>
                   <li>Cost effective. Electricity consumption is many times lower than the electric heater and cost for electricity is lower than the cost of cooking gas.</li>
                   <li>Less time than gas cooking</li>
                </ul>

                </div>
               
               <h3>4 Capsense Induction Stove</h3>
                <div className='list'>
                <img src='image/4ic.png'></img>
                </div>
            
                <h3>7 Capsense Induction Stove</h3>
                <div className='list'>
                <img src='image/7ic.png'></img>
                </div>
            
                
                <h3>CCD Induction Stove</h3>
                <div className='list'>
                <img src='image/ccd.png'></img>
                </div>
                
         
              
        
    </div>
    <div class="about-col">
    
    <img src='image/ic.png'></img>
    
                
    </div>
   


   
   
 </div>
 
    
   
    </>
  )
}

export default Induction