import React from 'react'
import './medical.css'
import { Link } from 'react-router-dom'
const Medical = () => {
  return (
     <>
        <div className='home'>
               
    <div className='trending2'>
                <div className='container'>
                    <div className='left_box'>
                        <div className='header'>
                            <div className='heading'>
                                <h2 >Products</h2>
                            </div>
                            
                        </div>
                        <div className='products'>
                            <div className='container'>
                                <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/aps.png' alt=''></img>
                                        <h2>Aum Pulser Single Coil</h2>
                                        <Link to ='/aumpulser'><button className='btn'>View</button></Link> 
                                                                              
                                    </div>                
                                </div>
                                <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/aumpulser.png' alt=''></img>
                                        <h2>Aum Pulser Dual Coil</h2>
                                        <Link to ='/aumpulser'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/mat.png' alt=''></img>
                                        <h2>PEMF MAT</h2>
                                        <Link to ='/mat'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/pms.png' alt=''></img>
                                        <h2>PMS</h2>
                                        <Link to ='/pms'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                
                                <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/bio.png' alt=''></img>
                                        <h2>Biofield 6 Coil</h2>
                                        <Link to ='/biofield'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                <div className='box'>
                                    
                                    <div className='img_box'>
                                        <img src='image/BF-2.png' alt=''></img>
                                        <h2>Biofield 12 Coil</h2>
                                        <Link to ='/biofield'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/knee.png' alt=''></img>
                                        <h2>PEMF Knee Braces</h2>
                                        <Link to ='/knee'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                
                               <div className='box'>
                                    <div className='img_box'>
                                        <img src='image/alert.png' alt=''></img>
                                        <h2>Alert Seat</h2>
                                        <Link to ='/alert'><button className='btn'>View</button></Link>                                        
                                    </div>                
                                </div>
                                

                            </div>
                        </div>
                    </div>
                    <div className='right_box'>
                        <div className='container'>
                            <div className='testimonial'>
                                <div className='head'>
                                    <h3>Services</h3>
                                </div>
                                <div className='detail'>
                                    <div className='img_box'>
                                        <Link to ='/medical'><button className='btn'>Medical</button></Link>
                                    </div>
                                    
                                </div>
                                <div className='detail'>
                                    <div className='img_box'>
                                        <Link to ='/automotive'><button className='btn'>Automotive</button></Link>
                                    </div>
                                    
                                </div>
                                <div className='detail'>
                                    <div className='img_box'>
                                        <Link to ='/industrial'><button className='btn'>Industrial</button></Link>
                                    </div>
                                    
                                </div>
                                <div className='detail'>
                                    <div className='img_box'>
                                        <Link to ='/iot'><button className='btn'>IoT</button></Link>
                                    </div>
                                    
                                </div>
                                <div className='detail'>
                                    <div className='img_box'>
                                        <Link to ='/kitchen'><button className='btn'>Kitchen appliance</button></Link>
                                    </div>
                                    
                                </div>
                               
                               
                                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    
        </div>
        </>
      )
    }
    
  

export default Medical