import React from 'react'
import { Link } from 'react-router';
import './about.css'
const Tracker = () => {
  return (
    <>

    <br></br>
    
    
        <div className='blog'>
            
           
            <div className='last_header'>
               
                <div className='nav'>
                
                    <ul>
                        <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                        <li><Link to ='/mat' className='link'>MAT</Link></li>
                        <li><Link to ='/pms' className='link'>PMS</Link></li>
                        
                       
                        <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                        <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                        <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                        <li><Link to ='/induction' className='link'>Induction</Link></li>
                        <li><Link to ='/das' className='link'>DAS</Link></li>
                        <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                        <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                        <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                        <li><Link to ='/adas' className='link'>ADAS</Link></li>
    
                        
                        
                    </ul>
    
                </div>
                
                
            </div>
            
        </div>
    
    
    <div class="row">
    
  
    <div class="about-col">
      

        <h1>SMART TRACK</h1>
       
        <h3> The Smart tracking system is known as a real-time GPS tracking system. </h3>
              
                

                <div className='list'>
                <h3>Features of Smart Track device.</h3>
                <ul>
                    <p>Smart track device is used for tracking of vehicle's</p>
                    <li>Motion.</li>
                    <li>Speed.</li>
                    <li>Location.</li>
                    <li>Satellite view, live traffic view, and geofencing.</li>
                    <li>Repair and maintenance alerts.</li>
                </ul>

                </div>

                <div className='list'>
                <ul>
                    <p>The data collected from the GPS of the device are used for performance analysis of the vehicle</p>
                    <li>Recorded live tracking video.</li>
                    <li>Status of the network connection.</li>
                    <li>Technical parameters.</li>
                    <li>Alerts of driving behavior.</li>
                    <li>Geofencing-based attendance.</li>
                </ul>

                </div>
                
               
               
                
               
                
                
                
               
        
    </div>
    <div class="about-col">
    
    <img src='image/tracker.png'></img>
    
                
    </div>
   
   
 </div>

    </>
  )
}

export default Tracker