import React from 'react'

import { FaShippingFast } from "react-icons/fa";
import './nav.css'
import { Link } from 'react-router-dom';
const Nav = ({search, setSearch, searchproduct}) => {
  return (
    <>

    <div className='header'>
        <div className='top_header'>
            <div className='icon'>
                
                <FaShippingFast />

            </div>
            <div className='info'>
                <p>Shipping World Wide</p>
            </div>
        </div>
        <div className='mid_header'>
            <div className='logo'>
                <Link to='/'><img src='image/logo.PNG' alt='logo'></img></Link>
            </div>
            <div className='search_box'>
                <h2>Committed to Quality and Client satisfaction </h2>
                
            </div>
                               
        </div>
        <div className='last_header'>
            <div className='profile'>
                <h2>Welcome to Lipika Technologies</h2>
                

            </div>
            <div className='nav'>
            
                <ul>
                    <li><Link to ='/' className='link'>Home</Link></li>
                    <li><Link to ='/about' className='link'>About</Link></li>
                    
                    <li><Link to ='/shop' className='link'>Product</Link></li>
                    <li><Link to ='/contact' className='link'>Contact</Link></li>
                    <li><Link to ='/aumpulser' className='link'>Blog</Link></li>
                    
                    
                </ul>

            </div>
            
            
        </div>
        
    </div>

    </>
  )
}

export default Nav