import React from 'react'
import { Link } from 'react-router';
import './about.css'
const Pms = () => {
  return (
    <>

    <br></br>
    
    
        <div className='blog'>
            
           
            <div className='last_header'>
               
                <div className='nav'>
                    
                
                    <ul>
                        <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                        <li><Link to ='/mat' className='link'>MAT</Link></li>
                        <li><Link to ='/pms' className='link'>PMS</Link></li>
                        
                       
                        <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                        <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                        <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                        <li><Link to ='/induction' className='link'>Induction</Link></li>
                        <li><Link to ='/das' className='link'>DAS</Link></li>
                        <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                        <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                        <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                        <li><Link to ='/adas' className='link'>ADAS</Link></li>
    
                        
                        
                    </ul>
    
                </div>
                
                
            </div>
            
        </div>
    
    
   <div class="row">
    
  
    <div class="about-col">
      

        <h1>Pain Management System</h1>
       
        <h3> PMS, Pain Management System, is a modern-day electrotherapy device to treat nerve-related pain conditions.</h3>
                <p>PMS operates on the application of micro/milli electrical current for pain relief. PMS is battery operated device that has leads connected to sticky pads called electrodes. You attach the electrode pads in the pain-inflicted region. When switched ON, the device generates small electrical impulses at a particular frequency that stimulate across the surface of the skin and along the nerve strands.</p>
                <h3>description of modes</h3>
                <p>PMS device has predefined program modes with different frequency ranges. There are 5 modes, of which 3 are therapy modes and 2 are setting modes.</p>

                <div className='list'>
                <p>Therapy modes: Depending on the frequency ranges these modes are used to manage different types of pain.</p>
                <ul>
                    <li>MODE 1:  Used for Acute and Chronic pains.</li>
                    <li>MODE 2: Used for Tissue pains, increasing blood flow to the muscle, increasing range of motion, and tissue regeneration.</li>
                    <li>MODE 3: Used for nerve pains.</li>
                </ul>
                <p>Setting modes: These are used to change the device setting and to get feedback.</p>
                <ul>
                    <li>DATE SET: You can change the date and time device in this mode. By default, the device is set to Indian Standard Time.</li>
                    <li>USB MODE: In this mode, you can log and view all the therapy data on a  PC.</li>
                </ul>

                </div>
                         
                <h3>Benefits</h3>
                <p> Muscle Relaxants are predominately used to prevent, or reduce, muscle atrophy. Atrophy is weakening and loss of muscle tone, which is usually experienced after surgeries or injuries.</p>
                <div className='benifits'>
                    
                    <ul>
                        
                        <li>PMS has been proven to be an effective means of preventing muscle atrophy.</li>
                        <li>PMS also helps by increasing blood flow to muscles, increasing range of motion, increasing muscle strength, as well as enhancing muscle endurance.</li>
                        <li>PMS has pain management attributes in helping muscle-related pain, such as spastic muscle, sore muscles, or tight muscles.</li>
                        <li>PMS may be able to help reduce pain caused by various conditions like arthritis, knee pain, neck pain, back pain, and sports injuries.</li>
                    </ul>
                </div>
                
                <h3>General instruction to set up PMS</h3>
                <div className='inst'>
                    
                    <ul>
                        <li>1).Plug the power supply into an electrical socket and a red LED will glow.</li>
                        <li>2).Connect the power supply to the PMS Controller.</li>
                        <li>3).Plug the Splitter cable into the PMS controller.</li>
                        <li>4).Plug the Electrodes into the splitter cable.</li>
                        <li>5).Put the electrode pads on the pain regions of the body.</li>
                        <li>6).Press the 'ON/OFF' button to switch 'ON' the device.</li>
                        <li>7).The display will switch 'ON' and show the PMS logo screen and the display changes to the parameter selection screen.</li>
                        <li>8).The screen will now show "MODE: MODE 1", press 'START ' to enter the mode selection using the 'UP' and 'DOWN' keys. You can scroll to different modes using these keys. Press 'START' to select a suitable mode.</li>
                        <li>9).In the next line, you will see "TIME:00:15"(at this time you can go back to the mode selection line using the UP key). Press 'START'  to enter the time selection. Using the 'UP' and 'DOWN' keys you can vary the time setting. Press 'START' once you have selected the time.</li>
                        <li>10).In the next line using you will see "CUR: AC ON:(at this time you can go back to the mode selection line using the UP keys). Press the 'START' key to enter the current selection. Using the 'UP' and 'DOWN' keys you can scroll between AC/DC modes available. Select the current mode and start the therapy press the start key.</li>
                        <li>11).The device starts the Therapy session and shows all the parameters you selected.</li>
                        <li>12).By default, the pulse intensity level is set to 5%. To increase the pulse intensity you can use the 'UP' key again. Intensity level is based on skin resistance and group of muscles which varies from person to person. The maximum selection level is 100%.</li>
                        <li>13).To reboot to the main menu press 'START' again.</li>
                        <li>14).To view data logs on PC, connect the USB cable to PMS and PC. Navigate to USB mode and press the 'START' key to turn ON and OFF the UAB connection. </li>

                    </ul>
                </div>
             
                
                
                
                <h3>PRECAUTIONS</h3>
                <div className='inst'>
                    
                    <p>To ensure the safety of the user, and the by stander, please pay attention to the following safety instructions.</p>
                    <ul>
                        <li><b>PREGNANCY:</b> Pregnant women should not use the device at all. </li>
                        <li><b>PACEMAKERS: </b>People with Heart pacemakers, Deep brain stimulators, and other electronic implants should not use the device at all. </li>
                        <li><b>Do not permit</b>use by children unable to understand the instructions or persons with cognitive disabilities. </li>
                        <li><b>Do not</b> shorten the electrodes. </li>
                        <li>Power supply 12 VDC, 1.8 Amps only. Don't use other power supplies. </li>
                        <li><b>Do not</b>stick the electrodes too close to each other power supplies. </li>
                        <li><b>Do not use</b>a non-rechargeable battery. </li>
                        <li>Device<b>should not</b>be used while connected to USB. </li>
                        <li><b>Do not use</b> when driving, operating machinery, or any action needing muscular control.</li>
                        
                    </ul>
                </div>
                <h3>DISCLAIMER</h3>
                <div className='inst'>
                   
                    <ul>
                        <li>Lipika Technologies makes no medical claims real or implied as to the benefit of our device and methods.</li>
                        <li>Our product is not intended to be used to diagnose, treat, cure, or prevent any disease.</li>
                        <li>Any information provided by us is not to be construed as medical advice.</li>
                    </ul>
                </div>
        
    </div>
    <div class="about-col">
    
    <img src='image/pms.png'></img>
    <img src='image/pms-1.png'></img>
    <img src='image/pms-2.png'></img>
    <img src='image/pmst1.png'></img>
    <img src='image/pmst2.png'></img>
    <img src='image/pmst3.png'></img>
    
                
    </div>
   
   
 </div>
 
    </>
  )
}

export default Pms