import React from 'react'
import { Routes, Route } from 'react-router';
import Home from './home';
import Shop from './shop';
import Contact from './contact';
import Detail from './detail';
import Aumpulser from './aumpulser';
import Mat from './mat';
import Pms from './pms';
import Knee from './knee';
import Biofield from './biofield';
import Soundbox from './soundbox';
import Poe from './poe';
import Das from './das';
import Induction from './induction';
import Medical from './medical';
import Automotive from './automotive';
import About from './about';
import Kitchen from './kitchen';
import Iot from './iot';
import Industrial from './industrial';
import Alert from './alert';
import Tracker from './tracker';
import Silver from './silver';
import Adas from './adas';



const Rout = ({shop,Filter,allcatefilter}) => {
  return (
    <>
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/shop' element={<Shop shop={shop} Filter={Filter} allcatefilter={allcatefilter}/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/detail' element={<Detail/>}/>
        <Route path='/aumpulser' element={<Aumpulser/>}/>
        <Route path='/pms' element={<Pms/>}/>
        <Route path='/mat' element={<Mat/>}/>
        <Route path='/knee' element={<Knee/>}/>
        <Route path='/biofield' element={<Biofield/>}/>
        <Route path='/soundbox' element={<Soundbox/>}/>
        <Route path='/poe' element={<Poe/>}/>
        <Route path='/das' element={<Das/>}/>
        <Route path='/induction' element={<Induction/>}/>
        <Route path='/medical' element={<Medical/>}/>  
        <Route path='/automotive' element={<Automotive/>}/>
        <Route path='/about' element={<About/>}/> 
        <Route path='/kitchen' element={<Kitchen/>}/> 
        <Route path='/iot' element={<Iot/>}/>    
        <Route path='/industrial' element={<Industrial/>}/>
        <Route path='/alert' element={<Alert/>}/>
        <Route path='/tracker' element={<Tracker/>}/>
        <Route path='/silver' element={<Silver/>}/>
        <Route path='/adas' element={<Adas/>}/>
         

    </Routes>

    </>
  )
}

export default Rout