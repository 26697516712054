import React from 'react'
import { Link } from 'react-router';

const Detail = () => {
  return (
    <>
       
        <div className='shop'>
        <h2># Details</h2>
        <p>Home . Details</p>
        <div className='container'>
            <div className='left_box'>
                <div className='category'>
                    <div className='header'>
                        <h3>All Product</h3>
                    </div>
                    <div className='box'>
                        <ul>
                        
                        <Link to='/aumpulser' className='link'><li>#aum pulser</li></Link>
                        <Link to='/mat' className='link'><li>#PEMF MAT</li></Link>
                        <Link to='/pms' className='link'><li>#PMS</li></Link>
                        <Link to='/knee' className='link'><li>#KNEE Braces</li></Link>
                        <Link to='/biofield' className='link'><li>#BIOFIELD</li></Link>
                        <Link to='/soundbox' className='link'><li>#SOUND BOX</li></Link>
                        <Link to='/das' className='link'><li>#DAS</li></Link>
                        <Link to='/poe' className='link'><li>#POE</li></Link>
                        <Link to='/induction' className='link'><li>#INDUCTION</li></Link>
                           
                            
                        </ul>
                    </div>
                </div>
                

            </div>
            <div className='right_box'>
                <div className='product_box'>
                    <h2>Product Details</h2>
                    <div className='product_container'>
                      <p>
                      As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​ Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing and technology and the term is more recognizable than ever before. The Information Technology umbrella can be quite large, covering many fields. IT professionals perform a variety of duties that range from installing applications, managing information, to designing complex applications, managing computer networks and designing and managing databases.
                      </p>
                                                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    
    </>
    )
  }
    export default Detail