import React from 'react'
import { Link } from 'react-router';
import './about.css'

const Knee = () => {
  return (
    <>
    <br></br>
    
    
        <div className='blog'>
            
           
            <div className='last_header'>
               
                <div className='nav'>
                
                    <ul>
                        <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                        <li><Link to ='/mat' className='link'>MAT</Link></li>
                        <li><Link to ='/pms' className='link'>PMS</Link></li>
                        
                       
                        <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                        <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                        <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                        <li><Link to ='/induction' className='link'>Induction</Link></li>
                        <li><Link to ='/das' className='link'>DAS</Link></li>
                        <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                        <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                        <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                        <li><Link to ='/adas' className='link'>ADAS</Link></li>
    
                    
                        
                    </ul>
    
                </div>
                
                
            </div>
            
        </div>
    
    
        
    <div class="row">
    
  
        <div class="about-col">
          

            <h1>PEMF Knee Braces</h1>
           
            <h3> Non-pharmacological therapy for knee pain is PEMF Knee braces. </h3>
                    
                    <h3>Benefits</h3>
                    <div className='benifits'>
                        
                        <ul>
                            <li>Reduced knee pain</li>
                            <li>Improved blood circulation</li>
                            <li>Enhanced recovery process</li>
                            <li>Non-invasive device</li>
                            <li>Convenient to use</li>

                            </ul>
                    </div>
                    
                    <h3>General instruction to set up Knee Bracesr</h3>
                    <div className='inst'>
                        
                        <ul>
                            <li>1).Brace the Knee Braces with a PEMF coil on the knee.</li>
                            <li>2).Connect the power supply cord to the respective connector in the controller.</li>
                            <li>3).Plug in the connectors and socket and turn on the switch.</li>
                            <li>4).Red led on the controller starts blinking once the device is powered on.</li>
                            <li>5).Red led blinking indicates therapy has started successfully.</li>
                        </ul>
                    </div>
                    
                    
                    
                    
                    <h3>PRECAUTIONS</h3>
                    <div className='inst'>
                        
                        <p>To ensure the safety of the user, the patient and the bystanders, please pay attention to the following safety instructions.</p>
                        <ul>
                            <li><b>Pacemakers:</b>people with Heart pacemakers, Deep brain stimulators, and other Electronic implants should not use the device at all.</li>
                            <li><b>Cleaning:</b>The knee braces device can be gently cleaned by a piece of cloth. Knee braces have to be switched off and separated from the controller/ power adapter before cleaning.</li>
                            <li><b>Water/Moisture: </b>Do not immerse the device or its accessories in liquid! Liquid ingression may cause serious damage and the device can be unusable.</li>
                            <li><b>Power supply:</b>Do not operate the controller at more than 5V, 2Ampere DC input or waveform will deteriorate causing more than good.</li>
                        </ul>
                    </div>
                    
            
        </div>
        <div class="about-col">
        
        <img src='image/knee.png'></img>
        
        
                    
        </div>
       
       
     </div>
     
    
    </>
  )
}

export default Knee