import React, { useState } from 'react'
import Nav from './comp/nav'
import { BrowserRouter } from 'react-router-dom'
import Rout from './comp/rout'
import Footer from './comp/footer'
import Homeproduct from './comp/home_product'
const App = () => {
  // shop product page
  const [shop, setShop] = useState(Homeproduct)
    //SHOP SEARCH
    const [search, setSearch]=useState('')
  //shop category filter
  const Filter = (x)=>
  {
    const catefilter =Homeproduct.filter((product)=>
    {
      return product.cat === x
    })
    setShop(catefilter)
  }
  const allcatefilter=()=>
  {
    setShop(Homeproduct)
  }
  //search product
  const searchlength=(search || []).lenght === 0
  const searchproduct=()=>
  {
  if(searchlength)
  {
    alert('please search product!')
    setShop(Homeproduct)
  }
  else
  {
    
      
        const searchfilter = Homeproduct.filter((x)=>
        {
          return x.cat === search
        })
        setShop(searchfilter)
      
    

  }
  }
  
  return (
    <>
    <BrowserRouter>
    <Nav search={search} setSearch={setSearch} searchproduct={searchproduct}/>
    <Rout shop={shop} Filter={Filter} allcatefilter={allcatefilter}/>
    <Footer />
    </BrowserRouter>
    
    </>
  )
}

export default App