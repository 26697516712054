import React from 'react'
import { Link } from 'react-router';
import './detail.css'

const Soundbox = () => {
  return (
    <>
     <br></br>
     
     
         <div className='blog'>
             
            
             <div className='last_header'>
                
                 <div className='nav'>
                 
                     <ul>
                         <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                         <li><Link to ='/mat' className='link'>MAT</Link></li>
                         <li><Link to ='/pms' className='link'>PMS</Link></li>
                         
                        
                         <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                         <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                         <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                         <li><Link to ='/induction' className='link'>Induction</Link></li>
                         <li><Link to ='/das' className='link'>DAS</Link></li>
                         <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                         <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                         <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                         <li><Link to ='/adas' className='link'>ADAS</Link></li>
     
                         
                         
                     </ul>
     
                 </div>
                 
                 
             </div>
             
         </div>
     
     
     <div class="row">
    
  
    <div class="about-col">
      

        <h1>SOUND BOX</h1>
       
        <h3> A device for your growing business.</h3>
                <p>This compact device generates instant audio confirmation of the payment done through any wallet or UPI applications. </p>
                

                <div className='list'>
                <ul>
                <ul>
                           <li>Quick and seamless installation.</li>
                           <li>Instant audio payment confirmation.</li>
                           <li>Long life rechargeable battery.</li>
                           <li>No Wifi required, it connects through SIM.</li>
                           <li>Multiple language option</li>
                           <li>Faster confirmation.</li>
                           
                       </ul>
                </ul>

                </div>
                
               
                <h3>Features</h3>
                <div className='benifits'>
                <ul>
                               <li>Easy to operate.</li>
                               <li>Bluetooth capability to use it as a bluetooth speaker.</li>
                               <li>Volume control keys with audio feedback.</li>
                               <li>Replay button to listen last 5 transaction.</li>
                               <li>Single RGB LED to indicate power on and charging indication.</li>
                               <li>Supports micro SIM card.</li>
                               <li>Micro USB cable to recharge the device.</li>


                            </ul> 
                    
                </div>
                
                <h3>How to operate QR sound box:</h3>
                <div className='inst'>
               
                           <ul>
                            <li>Charge the device completely using type A USB cable, red LED indicates charging and blue light indicates fully charged.</li>
                            <li>Click the ON/OFF button and the device will turn on and announce the power on message.</li>
                            <li>Wait  till the device is connect to the network. Once it is connected the device will announce the status. And device is ready to go.</li>
                            <li>Using the smartphone app, scan QR code and do the transaction.</li>
                            <li>The device will announce the payment instantly.</li>
                            <li>Use + or - buttons to increase or decrease the volume.</li>
                            <li>Use the replay button to check the previous transaction. You can check last 5 transactions.</li>
                            <li>Bluetooth Connectivity</li>
                            <li>Go to the bluetooth setting the smartphone and search for the device ID.</li>
                            <li>Connect the device through bluetooth and use it as speaker to listen to the music or run audio advertisement</li>

                           </ul>
                    
                        
                </div>
    </div>
    <div class="about-col">
    
    <img src='image/soundbox.png'></img>
    <img src='image/soundbox1.png'></img>
    
                
    </div>
   
   
 </div>
 

  
       
    </>
  )
}

export default Soundbox