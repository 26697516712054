import React from 'react'
import { Link } from 'react-router';
import './about.css'

const About = () => {
  return (
    <>
     
     
     <div class="row">
        <div class="about-col">
            
            <h1>The Company</h1>
            <p> LIPIKA Technologies, we are one of the leading design, development and manufacturing company in India.  We have a dedicated team of technocrats and management people with rich experience. We are a MSME registered company.
            </p>
            <p>The company is extensively involved in developing new tech-products contributing to various industries such as Medical, Automotive, Industrial and Commercial sector. Our aim is to engage in people's wellbeing through smart and innovative approach. We are one of the leading manufacturers and exporter of PEMF (Pulsed Electro Magnetic Field) devices. 
            </p>
            <p>OUR Motto signifies “Commitment to Quality and Client satisfaction”. We are an ISO 9001:2015 certified company and in the process of obtaining ISO 13485 and MDD certification. We have patents being registered.</p>
            <p>Lipika Technologies is committed to ensure the widest reach & excellent support to customer service by maintaining consistency with continuous product improvement irrespective of the business the customer brings us. It is this commitment to quality that has not only helped us in retaining the customers but also in exploiting and innovating greener pastures for continuous technical improvement.
            </p>
            <p>We comply and abide by strict quality standards for manufacturing and never compromise on the quality. And hence we always use 1st grade components/material and use latest technology.
Our dedicated R&D team is constantly working to provide new and innovative solutions to our customers. We believe in “Make in India” concept and encourage all our “Make in India” suppliers. We are also registered under Udyog Aadhar.

</p><br></br>
<br></br>
           <h1>The Team</h1>
           <h3>We are always ready to help. </h3>
           <p>Our core strength rather the company's pillars are our skilled and dedicated employees  who are certified experts, engineers, professionals who add enduring value to the company's growth and success.
           </p>
           <p>The Development and Production teams of Lipika Technologies are trained to solve even the most complex and unique technical problems of any products or systems. The  team consists of quality control specialists, lab technicians, test experts, logistics, designers and engineers.

</p>
<p>Our production team has solid expertise in high-mix/lowvolume, micro/macro assembly line and we have expertise in production of medical devices, industrial automation products, consumer electronic equipment, automotive products and IoT.

</p>
<p>Our well experienced Management team has ISO 9001 : 2015 (quality management system) certification and we are in process with ISO 13485 : 2016 (Quality management for medical grade product manufacturing) certification.

</p>
<h3>The Facility</h3>
<p>Our office and manufacturing facility is located in the heart of the Bangalore City. #56, 1st floor, 19th main, 2nd block, Rajajinagar, 560010. We have well allocated office and well maintained cleanroom facility for the production.
</p>
<p>We have unique tool set for individual tasks in the production.
</p>
<div className='list'>

<ul>
  <li>Product body leakage tester - Current leakage test for the products in case of AC mains earth leakage issues</li>
  <li>FADOS - for cold testing the PCB without powering on the device</li>
  <li>High speed digital oscilloscope - for digital / analog input / output signal testing</li>
  <li>Calibrated, digital / analog meters to test different parameters to identify the best quality units</li>
</ul>
</div>
        </div>
        <div class="about-col">
        <img src='image/about.jpg'></img>
        <img src='image/about3.jpg'></img>
        <img src='image/about1.jpg'></img>

        </div>
     </div>
        
    
    
    </>
    
    

    
    
  )
}

export default About