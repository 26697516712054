import React from 'react'
import { Link } from 'react-router';
import './about.css'
const Silver = () => {
  return (
    <>
    <br></br>


    <div className='blog'>
        
       
        <div className='last_header'>
           
            <div className='nav'>
            
                <ul>
                    <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                    <li><Link to ='/mat' className='link'>MAT</Link></li>
                    <li><Link to ='/pms' className='link'>PMS</Link></li>
                    
                   
                    <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                    <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                    <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                    <li><Link to ='/induction' className='link'>Induction</Link></li>
                    <li><Link to ='/das' className='link'>DAS</Link></li>
                    <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                    <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                    <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                    <li><Link to ='/adas' className='link'>ADAS</Link></li>

                    
                    
                </ul>

            </div>
            
            
        </div>
        
    </div>

























        
    <div class="row">
    
  
        <div class="about-col">
          

            <h1>Nano Colloidal Silver Spary</h1>
           
            <p>For decades the emergence of multi drug resistant microbes, hospital and community acquired infections are often more threatening to the patient and cost the healthcare system more than the treatment for the original syndrome or injury for which the patient was treated.  These antibiotic resistance microbes and the concentration of pharmaceutical research in other markets have combined to open up a major opportunity in a growing $10 billion wound care market for AB Nylon which has been assigned with Omni Shield® technology/patent. The wound care market includes both acute or chronic segments. Acute wounds typically result from trauma or surgery, and chronic wound typically develops for many pathological reason such as aging, blood supply insufficiencies, or suppressed immune systems. AB Nylon will provide products for both the acute and chronic markets.</p>







                    
                    
                    
                    <h3>Description  </h3>
                    <div className='inst'>
                        
                        <p>It is well known that silver has a broad spectrum of antimicrobial (antibacterial and antifungal) activity. The microbial species that are associated with wound colonization and wound infections fall within this spectrum. Further, unlike antibiotics, there is no evidence to date of the emergence of resistance to silver by microbial species that infect wounds.</p>
                        <p>Until now, the use of silver as an anti-infective agent has been limited by the inability to deliver a sufficient number of silver ions over a period of time to the sites of potential and actual infections.</p>
                    <p>AB Nylon’s  line of advanced wound and burn care products will be in the forefront of wound care products with outstanding ability to deliver sufficient number of silver ions to wound sites. 
Our products are active against a broad range of bacteria, fungi and viruses, including many antibiotic resistant bacteria, such as methicillin-resistant Staphylococcus aureus (MRSA) and vancomycin resistant Enterococci (VRE)
</p>

                    
                    
                    </div>
                    <h3>Usage</h3>
                    <div className='inst'>
                       <p>As a supplement in treatment of wide range of infections due to yeast; bacteria (tuberculosis, Lyme disease, bubonic plague, pneumonia, leprosy, gonorrhea, syphilis, scarlet fever, stomach ulcers, cholera); parasites (ringworm, malaria); and viruses (pneumonia, herpes, shingles, warts).</p>
                       
                    </div>
            
        </div>
        <div class="about-col">
        
        <img src='image/silver.png'></img>
        <img src='image/silver2.png'></img>
       
                    
        </div>
       


       
       
     </div>
     
        


          </>
  )
}

export default Silver