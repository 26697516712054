import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import './contact.css'


const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const[country,setCountry]=useState('');
  const[code,setCode]=useState('');
  const[product,setProduct]=useState('');
  const[quantity,setQuantity]=useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_79goqw3';
    const templateId = 'template_nyggm0r';
    const publicKey = '2GaaH9cUmxmaLIXY5';

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      email: email,
      to_name: 'Lipika Tecnologies',
      country:country,
      code:code,
      product:product,
      quantity:quantity,
      message: message,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setCountry('');
        setCode('');
        setProduct('');
        setQuantity('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (
    <div className='container-f'>
    <form onSubmit={handleSubmit} className='emailForm'>
    <div className='singleItem'>
                    <label htmlFor='name'>Name</label>
                    <input type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} required/>
                   
                </div>
                {/*----*/}

                {/*--singleitem--*/}
                <div className='singleItem'>
                    <label htmlFor='email'>E-mail</label>
                   <input type="email" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    
                </div>
                {/*----*/}

                {/*--singleitem--*/}
                <div className='singleItem'>
                    <label htmlFor='country'>Country</label>
                   <input type='text' placeholder='Enter Your country' value={country} onChange={(e)=> setCountry(e.target.value)} required/>
                </div>
                {/*----*/}

                {/*--singleitem--*/}
                <div className='singleItem'>
                    <label htmlFor='code'>Zip-Code</label>
                    <input type='text' placeholder='Eter Your country code' value={code} onChange={(e)=> setCode(e.target.value)} required/>
                </div>
                {/*----*/}

                {/*--singleitem--*/}
                <div className='singleItem'>
                    <label htmlFor='product'>Product</label>
                    <select placeholder='Enter product required' value={product} onChange={(e)=> setProduct(e.target.value)} required>
  <option value="">--Please choose product required--</option>
  <option value="Aum pulser dual coi">Aum pulser dual coil</option>
  <option value="Aum pulser pulser single coil">Aum pulser pulser single coil</option>
  <option value="Aum pulser pulser Controller">Aum pulser pulser Controller</option>
  <option value="Aum pulser pulser Adapter">Aum pulser pulser Adapter</option>
  <option value="Aum pulser pulser coil">Aum pulser pulser coil</option>
  <option value="PEMF MAT">PEMF MAT</option>
  <option value="PEMF MAT Controller">PEMF MAT Controller</option>
  <option value="PEMF MAT Adapterr">PEMF MAT Adapter</option>
  <option value="PMS">PMS</option>
  <option value="PMS Controller">PMS Controller</option>
  <option value="PMS Adapter">PMS Adapter</option>
  <option value="Biofield 6 coil">Biofield 6 coil</option>
  <option value="Biofield 12 coil">Biofield 12 coil</option>
  <option value="Biofield coil">Biofield coil</option>
  <option value="Biofield Adapter">Biofield Adapter</option>
  <option value="PEMF Knee Braces">PEMF Knee Braces</option>
  <option value="PEMF Knee Braces Adapter">PEMF Knee Braces Adapter</option>
  <option value="Sound Box">Sound Box</option>
  <option value="DAS">DAS</option>
  <option value="POE">POE</option>
  <option value="4 Capsense Induction">4 Capsense Induction</option>
  <option value="7 Capsense Induction">7 Capsense Induction</option>
  <option value="CCD Induction">CCD Induction</option>
  <option value="Tracker">Tracker</option>
  <option value="Colloidal silver">Colloidal silver</option>
  <option value="Alert Seat">Alert Seat</option>
  <option value="ADAS">ADAS</option>
  <option value="Others">Others</option>

</select>
                    
                </div>
                {/*----*/}

                 {/*--singleitem--*/}
                 <div className='singleItem'>
                    <label htmlFor='quantity'>Quantity</label>
                    <input type='text' placeholder='Enter number of products' value={quantity} onChange={(e)=> setQuantity(e.target.value)} required/>
                </div>
                {/*----*/}

                {/*-singleitem---*/}
                <div className='textAreasingleItem'>
                    <label htmlFor='query'>Query</label>
                   <textarea cols="30" rows="10"  value={message} onChange={(e) => setMessage(e.target.value)} placeholder='message...!' required> </textarea>
                    
                </div>
                {/*----*/}
                
                <div className='btn'>
                    <button type='submit'>Submit</button>
                </div>

      
      
      
      
     
      
      
     
    </form>
    </div>
  )
}

export default Contact