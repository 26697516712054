import React, { useState } from 'react'
import './shop.css'

import { Link } from 'react-router';
import { IoIosCloseCircleOutline } from "react-icons/io";


const Shop = ({shop,Filter,allcatefilter}) => {
    const[showDetail, setShowDetail]= useState(false)
    const [detail, setDetail] =useState([])
    const detailpage =(product)=>
    {
        setDetail([{product}])
        setShowDetail(true)
    }
    console.log(detail)
    const closedetail = () =>
    {
        setShowDetail(false)

    }
  return (
    <>
    {
        showDetail ?
        <>
        <div className='product_detail'>
            <button className='close_btn' onClick={closedetail}><IoIosCloseCircleOutline /></button>
            <div className='container'>
                <div className='img_box'>
                    <img src='' alt=''></img>
                </div>
            </div>
        </div>

        </>
        :null
    }
    
    <div className='shop'>
        <h2># Products</h2>
        <p>Home . Product</p>
        <div className='container'>
            <div className='left_box'>
                <div className='category'>
                    <div className='header'>
                        <h3>All categories</h3>
                    </div>
                    <div className='box'>
                        <ul>
                            <li onClick={()=> allcatefilter()}>#All</li>
                            <li onClick={()=> Filter ("aum pulser")}># aum pulser</li>
                            <li onClick={()=> Filter ("mat")}># mat</li>
                            <li onClick={()=> Filter ("pms")}># pms</li>
                            <li onClick={()=> Filter ("knee braces")}># knee braces</li>
                            <li onClick={()=> Filter ("biofield")}># biofield</li>
                            <li onClick={()=> Filter ("sound box")}># sound box</li>
                            <li onClick={()=> Filter ("das")}> # das</li>
                            <li onClick={()=> Filter ("poe")}># poe</li>
                            <li onClick={()=> Filter ("induction")}># induction</li>
                            <li onClick={()=> Filter ("tracker")}># tracker</li>
                            <li onClick={()=> Filter ("silver")}># colloidal silver</li>
                            <li onClick={()=> Filter ("alert")}># alert seat</li>
                            <li onClick={()=> Filter ("adas")}># adas</li>
                            
                        </ul>
                    </div>
                </div>
                

            </div>
            <div className='right_box'>
                <div className='product_box'>
                    <h2>Product Details</h2>
                    <div className='product_container'>
                        {
                            shop.map((curElm)=>
                            {
                                return(
                                    <>
                                    <div className='box'>
                                        <div className='img_box'>
                                            <img src={curElm.image} alt=''></img>
                                            
                                        </div>
                                        <div className='detail'>
                                            <h3>{curElm.Name}</h3>
                                            
                                            <Link to ='/contact'><button> Contact Us</button></Link>
                                            
                                            

                                        </div>
                                    </div>
                                    </>
                                )

                            })
                        }
                        
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    
    </>
  )
}

export default Shop