import React from 'react'
import { Link } from 'react-router';
import './detail.css'

const Poe = () => {
  return (
    <>
       <br></br>
       
       
           <div className='blog'>
               
              
               <div className='last_header'>
                  
                   <div className='nav'>
                   
                       <ul>
                           <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                           <li><Link to ='/mat' className='link'>MAT</Link></li>
                           <li><Link to ='/pms' className='link'>PMS</Link></li>
                           
                          
                           <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                           <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                           <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                           <li><Link to ='/induction' className='link'>Induction</Link></li>
                           <li><Link to ='/das' className='link'>DAS</Link></li>
                           <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                           <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                           <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                           <li><Link to ='/adas' className='link'>ADAS</Link></li>
       
                           
                           
                       </ul>
       
                   </div>
                   
                   
               </div>
               
           </div>
       
        
        <div class="row">
    
  
    <div class="about-col">
      

        <h1>PoE Injector</h1>
        <p>A Power over Ethernet (PoE) Injector is a device used in networking to provide both data and electrical power to network devices over a single Ethernet cable. This can be particularly useful in situations where it's difficult to provide a separate power source for certain devices, such as IP cameras, wireless access points, IP phones, and other network-connected equipment. </p>
        <img src='image/poe1.png'></img>
        <p>The PoE Injector for Network Devices is an essential component for simplifying your network setup, providing seamless power and data connectivity to compatible devices. With the power of PoE technology, you can eliminate the need for additional power cables and outlets, reducing clutter and installation complexity.</p>
        <p>Power over Ethernet (PoE) is technology that integrates data and power over an Ethernet cable to provide greater flexibility in the modern workplace environment. PoE makes it possible to supply power to network device such as wireless access point, network cameras, and IP phones among others since it integrate power into a single standard LAN infrastructure. </p>
        <p>PoE injectors have three ports: A power input, a data input, and a power/data output. Simply connect an Ethernet cable from a non-PoE switch to the injector, plug in the power cable, and then run a third PoE cable from the injector to your PoE enabled powered device</p>
        <img src='image/poe2.png'></img>
        <p>PoE installation is fast and cheap, especially when compared to older, outdated methods that require two separate cables one for power, and one for data. Evidently replacing the millions of non-PoE compatible switches is not a viable solution, nor is unnecessarily running two cables to a device that could be operated with just one cable.</p>
        <h3>Key Features</h3>
                <div className='inst'>
                                     
                    <ul>
                        <li>Single Cable Solution</li>
                        <li>Universal Compatibility</li>
                        <li>Plug-and-Play Installation</li>
                        <li>Safe Power Delivery</li>
                        <li>Compact and Sturdy Design</li>
                        <li>LED Indicators</li>
                        <li>Optimized Network Performance</li>
                        <li>Versatile Deployment</li>
                        
                    </ul>
                </div>
               
                <h3>General specification</h3>
                <div className='inst'>
                                     
                    <ul>
                        <li>Power consumption: 35W single output</li>
                        <li>Efficiency: 84%(min)</li>
                        <li>Output voltage @48VDC input: 57VDC, 650Ma</li>
                        <li>Load regulation: +/-2%</li>
                        <li>Line regulation: +/-1%</li>
                        <li>Voltage accuracy: +/-2%</li>
                    </ul>
                </div>
                
        <h3>Input and output specifications</h3>
                <div className='inst'>
                                     
                    <ul>
                        <li>Input voltage range: 36-72VDC    </li>
                        <li>Output voltage: 57VDC 650Ma</li>
                        <li>Maximum current with load: 0.7A</li>
                    </ul>
                </div>

                <h3>Protections</h3>
                <div className='inst'>
                                     
                    <ul>
                        <li>Overload: Auto recovery</li>
                        <li>Output short circuit: Auto recovery</li>
                        <li>Current limiting: Auto recovery</li>
                        <li>Reverse polarity: Auto recovery</li>
                        <li>Inline surge protection: 4KA</li>
                    </ul>
                </div>

               
        <h3>Mechanical characteristics</h3>
                <div className='inst'>
                                     
                    <ul>
                        <li>Enclosure: Adapter type</li>
                        <li>Enclosure material: ABS Black</li>
                        <li>Dimensions: 135 x 68 x 38 mm</li>
                        <li>Cords / sockets: Input through 3pin connector and Output through Dual RJ45 PORTS</li>
                        <li>Weight: 223g</li>
                        <li>Ethernet: GIGABIT</li>
                        <li>Cooling: self</li>
                        <li>Indicators: Red LED (Power ON)</li>
                    </ul>
                </div>
                
                
                
                <h3>Environmental characteristics</h3>
                <div className='inst'>
                                     
                    <ul>
                        <li>Operating Temperature: 0 TO 60 C</li>
                        <li>Storage temperature: -10 TO 70 C</li>
                        <li>Operating humidity :5 TO 95% Non- condensing</li>
                    </ul>
                </div>
                
               
        
    </div>
    <div class="about-col">
    <img src='image/poe.png'></img>
    <img src='image/poe3.jpg'></img>
    <img src='image/ppoe4.jpg'></img>
    <img src='image/poe5.jpg'></img>
                
    </div>
   
   
 </div>
 
    </>
  )
}

export default Poe