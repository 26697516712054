import React from 'react'
import { Link } from 'react-router';
import './about.css'

const Das = () => {
  return (
    <>
        <br></br>
        
        
            <div className='blog'>
                
               
                <div className='last_header'>
                   
                    <div className='nav'>
                    
                        <ul>
                            <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                            <li><Link to ='/mat' className='link'>MAT</Link></li>
                            <li><Link to ='/pms' className='link'>PMS</Link></li>
                            
                           
                            <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                            <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                            <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                            <li><Link to ='/induction' className='link'>Induction</Link></li>
                            <li><Link to ='/das' className='link'>DAS</Link></li>
                            <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                            <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                            <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                            <li><Link to ='/adas' className='link'>ADAS</Link></li>
        
                            
                            
                        </ul>
        
                    </div>
                    
                    
                </div>
                
            </div>
        
        
        <div class="row">
    
  
    <div class="about-col">
      

        <h1>DAS (Driver Alert System) </h1>
       <p>Driver drowsiness alert system is an advanced technology that uses computer vision to monitor a driver's level of alertness and detect signs of drowsiness.</p>
        <p>The system typically includes a camera mounted on the vehicle that captures images of the driver's face and eyes.</p>
        <p> The system analyzes these images in real-time, looking for signs of reduced eye aspect ratio</p>
        <p>When the system detects that the driver is becoming drowsy, it will alert the driver with an audible buzzer sound.</p>
            
                <h3>Package Content: The device contains</h3>
               <div className='benifits'>
               <img src='image/das1.png'></img>
                    <ul>
                        <li>USB camera - 1No.</li>
                        <li>Controller - 1No.</li>
                        <li>USB type A to type C cable - 1No.</li>
                        <li>12V Car Power adapter - 1No.</li>
                        <li>Camera holding stand - 1No.</li>
                        <li>GPS antenna - 1No.</li>
                        <li>GSM antenna - 1No.</li>
                        <li>Type C OTG cable - 1No</li>
                        
                    </ul>
                </div>
                
               
             
                
                
                
               
        
    </div>
    <div class="about-col">
    
    <img src='image/das.png'></img>
    
    
                
    </div>
   
   
 </div>
 
    </>
  )
}

export default Das