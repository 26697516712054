import React from 'react'
import { Link } from 'react-router';
import './detail.css'

const Mat = () => {
  return (
    <>
      <br></br>
      
      
          <div className='blog'>
              
             
              <div className='last_header'>
                 
                  <div className='nav'>
                  
                      <ul>
                          <li><Link to ='/aumpulser' className='link'>Aum Pulser</Link></li>
                          <li><Link to ='/mat' className='link'>MAT</Link></li>
                          <li><Link to ='/pms' className='link'>PMS</Link></li>
                          
                         
                          <li><Link to ='/knee' className='link'>Knee Braces</Link></li>
                          <li><Link to ='/alert' className='link'>Alert Seat</Link></li>
                          <li><Link to ='/biofield' className='link'>Biofield</Link></li>
                          <li><Link to ='/induction' className='link'>Induction</Link></li>
                          <li><Link to ='/das' className='link'>DAS</Link></li>
                          <li><Link to ='/silver' className='link'>Colloidal Silver</Link></li>
                          <li><Link to ='/soundbox' className='link'>Soundbox</Link></li>
                          <li><Link to ='/tracker' className='link'>Tracker</Link></li>
                          <li><Link to ='/adas' className='link'>ADAS</Link></li>
      
                          
                          
                      </ul>
      
                  </div>
                  
                  
              </div>
              
          </div>
      
      
      <div class="row">
    
  
    <div class="about-col">
      

        <h1>PEMF MAT</h1>
       
        <h3> PEMF MAT is used to augment performance and vitality.</h3>
                <p>The PEMF MAT device is specifically designed through this principle of the electromagnetic field. The idea of magnetic therapy utilizing the device by putting an external magnetic field near your body is to encourage the body to heal during times of illness and restore balance. Magnetic therapy is an alternative medical practice that uses magnets to treat pain, injury, medical conditions, and other health issues. The idea here is, if you suffer from any kind of pain, inflammation, or health condition, that magnetic fields can help your body heal. PEMF MAT works on the nature of pulsed electromagnetic fields (PEMF) means that they have a frequency in addition to intensity. This dynamic activity induces an electric charge in tissues and reaches more deeply into the body than a static magnetic field.</p>


                <h3>PEMF MAT has the following program modes for different end-use.</h3>

                <div className='list'>
                <ul>
                    <li>H-SLEEP – Suited for experiencing the best natural, deeper, and more rejuvenating sleep.</li>
                    <li>REVITALIZE – Suited for pain and injury management, helps to reduce the intensity of pain and accelerates the recovery process.</li>
                    
                </ul>

                </div>
              

               
                <h3>Benefits</h3>
                <div className='benifits'>
                    
                    <ul>
                        <li>Pain and Injury management.</li>
                        <li>When extremely sleep deprived or highly stressed.</li>
                        <li>To connect with Earth’s Frequency for rejuvenating and balance.</li>
                    </ul>
                    <p>Regular use of PEMF therapy, besides promoting various healing mechanisms, has been found to have substantially beneficial neuroendocrine, neurological, and psychological effects; as well as having the ability to promote bone, tissue, and nerve regeneration.
                    </p>
                    <p>The PEMF MAT device is specifically designed through this principle of the electromagnetic field. The idea of magnetic therapy utilizing the device by putting an external magnetic field near your body is to encourage the body to heal during times of illness and restore balance. Magnetic therapy is an alternative medical pract</p>
               <p>The idea here is, if you suffer from any kind of pain, inflammation, or health condition, that magnetic fields can help your body heal. PEMF MAT works on the nature of pulsed electromagnetic fields (PEMF) means that they have a frequency in addition to intensity. This dynamic activity induces an electric charge in tissues and reaches more deeply into the body than a static magnetic field.</p>
                </div>
                
                <h3>General instruction to set up MAT</h3>
                <div className='inst'>
                    
                    <ul>
                        <li>1).Plug the power supply into an electrical socket and a red LED will glow.</li>
                        <li>2).Connect the power supply to the MAT controller.</li>
                        <li>3).Plug the MAT coil cable into the MAT controller.</li>
                        <li>4).Press the 'ON/OFF' button to switch 'ON' the device.</li>
                        <li>5).Display will switch 'ON' and show the PEMF MAT logo screen and the display changes to the parameter selection screen. </li>
                        <li>6).The screen shows "MODE: H-SLEEP" and "TIME:8:00".</li>
                        <li>7).Press up start button to select the mode.</li>
                        <li>8).Select the mode using the UP/DOWN key.</li>
                        <li>9).After selecting the mode press the start button then the cursor moves to timer selection.</li>
                        <li>10).Select the timer using the UP/DOWN key.</li>
                        <li>11).Press the start button to start therapy.</li>
                        <li>12).Device starts the Therapy session and shows all the parameters you selected.</li>
                        <li>13).To reboot to the main menu press 'START' again.</li>
                    </ul>
                </div>
             
                
                
                
                <h3>PRECAUTIONS</h3>
                <div className='inst'>
                    
                     <ul>
                        <li><b>PREGNANCY:</b> Pregnant women Should Not Use the device at all.</li>
                        <li><b>PACEMAKERS:</b> people with Heart Pacemakers, Deep Brain Stimulators, and other Electronic implants Should Not use the device at all.</li>
                        <li><b>DO NOT put the control box unit</b> under pillows or mattresses as overheating may occur.</li>
                        <li><b>CLEANING:</b> MAT device can be gently cleaned by a piece of cloth. PEMF MAT has to be switched off and separated from the controller/power adapter before cleaning.</li>
                        <li><b>WATER/MOISTURE:</b>Do not immerse the device or its accessories in liquid!  Liquid ingression may cause serious damage and the device can be unusable.</li>
                        <li><b>POWER SUPPLY: </b>Do not operate the controller at more than 12 V 2.5 A DC input or waveform will deteriorate causing more than good.</li>
                    </ul>
                </div>
                <h3>DISCLAIMER</h3>
                <div className='inst'>
                   
                    <ul>
                        <li>Lipika Technologies makes no medical claims real or implied as to the benefit of our device and methods.</li>
                        <li>Our product is not intended to be used to diagnose, treat, cure, or prevent any disease.</li>
                        <li>
                        Any information provided by us is not to be construed as medical advice.</li>
                    </ul>
                </div>

                
        
    </div>
    <div class="about-col">
    
    <img src='image/mat.png'></img>
    
                
    </div>
   
   
 </div>
 
   
</>
  )
}

export default Mat