import React, { useState } from 'react'
import './home.css'
import { Link } from 'react-router-dom'



const Home = () => {
    


  return (
    <>
    <div className='home'>
        <div className='top_banner'>
            <div className='contant'>
                <h1>Welcome to Lipika Technologies</h1>
                <p>We are one of the leading design, development and manufacturing company in India. <br></br>We have a dedicated team of technocrats and management people with rich experience. We are a MSME registered company.</p>
    
                <Link to='/about' className='link'>Visit us to Know More</Link>
            </div>

        </div>


    
        


        <div className='trending0'>
            <div className='container'>
                <div className='left_box'>
                    <div className='header'>
                        <div className='heading'>
                            <h2>Services</h2>
                        </div>
                    </div>
                    <div className='products'>
                        <div className='container'>
                            <div className='box'>
                                <div className='img_box'>
                                <img src='image/medical.png'></img>
                                <Link to ='/medical'className='link'><h1>Medical</h1></Link>                                      
                                    <p>We work firmly in development of medical therapy technology.</p>
                                    
                                </div>
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/automative.png'></img>
                                    <Link to='/automotive' className='link'><h1>Automotive</h1></Link>
                                    <p>We are desired to work in automotive industry</p>
                                </div>
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/industrial.png'></img>
                                    
                                    <Link to='/industrial' className='link'><h1>Industrial</h1></Link>
                                    <p>We contribute in industrial automation</p>
                                </div>
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                <img src='image/commercial.jpg' className='link'></img>
                                <Link to='/iot' className='link'><h1>IoT</h1></Link>
                                    <p>We develop commercial equipments for day to day requirements.</p>
                                </div>
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                <img src='image/kitchen.png'></img>
                                    
                                    <Link to='/kitchen' className='link'><h1>Kitchen appliance</h1></Link>
                                    <p>We develop kitchen equipments for day to day requirements.</p>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div className='trending1'>
            <div className='container'>
                <div className='left_box'>
                    <div className='header'>
                        <div className='heading'>
                            <h2 >Certificate</h2>
                
                        </div>
                    </div>
                      
               </div>
            </div>       
        </div>           
        <div className='banners'>
            <div className='container'>
                <div className='left_box'>
                    <div className='box'>
                        <img src='image/iso.png' alt='ISO certification'></img>
                    </div>
                </div>
            </div>
        </div>







        
<div className='trending2'>
            <div className='container'>
                <div className='left_box'>
                    <div className='header'>
                        <div className='heading'>
                            <h2 >Products</h2>
                        </div>
                        
                    </div>
                    <div className='products'>
                        <div className='container'>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/aumpulser.png' alt=''></img>
                                    <h2>Aum Pulser</h2>
                                    <Link to ='/aumpulser'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/mat.png' alt=''></img>
                                    <h2>PEMF MAT</h2>
                                    <Link to ='/mat'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/pms.png' alt=''></img>
                                    <h2>PMS</h2>
                                    <Link to ='/pms'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/bio.png' alt=''></img>
                                    <h2>BIOFIELD</h2>
                                    <Link to ='/biofield'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/knee.png' alt=''></img>
                                    <h2>Knee Braces</h2>
                                    <Link to ='/knee'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/soundbox.png' alt=''></img>
                                    <h2>Sound Box</h2>
                                    <Link to ='/soundbox'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/das.png' alt=''></img>
                                    <h2>DAS</h2>
                                    <Link to ='/das'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/induction.png' alt=''></img>
                                    <h2>Induction</h2>
                                    <Link to ='/induction'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/poe.png' alt=''></img>
                                    <h2>PoE</h2>
                                    <Link to ='/poe'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/tracker.png' alt=''></img>
                                    <h2>Tracker</h2>
                                    <Link to ='/tracker'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>   
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/silver.png' alt=''></img>
                                    <h2>Colloidal Silver</h2>
                                    <Link to ='/silver'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/alert.png' alt=''></img>
                                    <h2>Alert Seat</h2>
                                    <Link to ='/alert'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>  
                            <div className='box'>
                                <div className='img_box'>
                                    <img src='image/adas.png' alt=''></img>
                                    <h2>ADAS</h2>
                                    <Link to ='/adas'><button className='btn'>View</button></Link>                                        
                                </div>                
                            </div>                                          
                        </div>
                    </div>
                </div>
                <div className='right_box'>
                    <div className='container'>
                        <div className='testimonial'>
                            <div className='head'>
                                <h3>Our Testimonial</h3>
                            </div>
                            <div className='detail'>
                                <div className='img_box'>
                                    <img src='image/info.png' alt='Testmonial'></img>
                                </div>
                                <div className='info'>
                                    <h3>VN</h3>
                                    <h4>client</h4>
                                    <p>(PEMF) I had to go to a chiropractor every two weeks because I had back pain and also cervical pain and it's been a month, the truth is I'm much better, I went to the chiropractor and he told me that I was very well. Thank you</p>
                                </div>
                            </div>
                            <div className='detail'>
                                <div className='img_box'>
                                    <img src='image/info.png' alt='Testmonial'></img>
                                </div>
                                <div className='info'>
                                    <h3>AC(Spain)</h3>
                                    <h4>client</h4>
                                    <p>A year and a half ago I suffered from tendonitis in my right elbow that could not be resolved even with DMSO, It wasn't unbearable pain but it was a continuous discomfort.I bought PEMF, pulsed electromagnetism therapy, high-power magnets. And i was all good.</p>
                                </div>
                            </div>
                            <div className='detail'>
                                <div className='img_box'>
                                    <img src='image/info.png' alt='Testmonial'></img>
                                </div>
                                <div className='info'>
                                    <h3>ACP</h3>
                                    <h4>client</h4>
                                    <p>ACP, 53 years old, tells us that she was also able to heal herself and leaves us a second testimony, she had more than 1 year with tendonitis, she was able to recover with PEMF, magnesium, diatomaceous earth, among other things.</p>
                                </div>
                            </div>
                                      
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    </>
  )
}

export default Home